

import React from 'react'

import Layout from '../Layout'
import InnerLayout from '../InnerLayout'

import { MeetingContactForm } from '../MeetingContactForm'

export const MeetingContactPage = ({ pageData }) => {

    return (
        <>
            <Layout
                title={pageData.mainTitle}
                devTitle={pageData.devTitle}
                
                backgroundImg="/img/contactPages/message.svg"
                seoTitle={`Kubevious :: ${pageData.mainTitle}`}
                seoDescription={pageData.subTitle}
            >
                <InnerLayout>
                    
                    <MeetingContactForm pageData={pageData} />
                    
                </InnerLayout>
            </Layout>
        </>
    )
}
