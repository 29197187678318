import React from 'react'
import { MeetingContactPage } from '../../../components/MeetingContactPage'

import { SAMURAI_REQUEST } from '../../../../content/data/contactPages'

const PlanSamuraiRequest = () => {

    return (
        <>
            <MeetingContactPage pageData={SAMURAI_REQUEST} />
        </>
    )
}

export default PlanSamuraiRequest
